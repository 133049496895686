<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="400"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--title">
        {{ formType === 'ADD' ? $t('billing.add') : $t('billing.edit') }}
      </div>
      <form @submit.prevent="submit">
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedBillingType') }"
        >
          <label class="col-12 col-lg-4">Jenis Billing</label>
          <div class="col-12 col-lg-8">
            <multiselect
              name="selectedBillingType"
              id="selectedBillingTypeField"
              v-model="selectedBillingType"
              :options="billingTypes"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :disabled="fromBillingDetail"
            />
            <span class="val-error">{{ validation.firstError('selectedBillingType') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('amount') }">
          <label class="col-12 col-lg-4">Harga Billing</label>
          <div class="col-12 col-lg-8">
            <cleave
              v-model="amount"
              :options="cleaveOptionCurrency"
              class="basic--input"
              name="amount"
              type="text"
              placeholder="Harga Billing"
            />
            <span class="val-error">{{ validation.firstError('amount') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-center mt-4">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ formType === 'ADD' ? $t('billing.btn.add') : $t('billing.btn.edit') }}
          </v-btn>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'modal-billing',
  mixins: [HelperMixin],
  components: { Multiselect },
  data() {
    return {
      modalName: 'modal-billing',
      billing: null,
      fromBillingDetail: false,
      dispatchAfterString: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.billing.form.loading,
      formType: (state) => state.billing.form.formType,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      billingTypes: (state) => state.billing.form.billingTypes,
    }),
    selectedBillingType: {
      get() {
        return this.$store.state.billing.form.selectedBillingType;
      },
      set(value) {
        this.$store.commit('billing/form/SET_SELECTED_BILLING_TYPE', value);
      },
    },
    amount: {
      get() {
        return this.$store.state.billing.form.amount;
      },
      set(value) {
        this.$store.commit('billing/form/SET_AMOUNT', value);
      },
    },
  },
  validators: {
    selectedBillingType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.billing.billingType.required'));
    },
    amount(value) {
      return Validator.value(value).required(this.$i18n.t('errors.billing.amount.required'));
    },
  },
  methods: {
    beforeOpen(event) {
      this.billing = event.params.billing;
      this.fromBillingDetail = event.params.fromBillingDetail;
      this.dispatchAfterString = event.params.dispatchAfterString;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async submit() {
      try {
        if (this.formType === 'ADD') {
          await this.$store.dispatch('billing/form/create');
        } else {
          await this.$store.dispatch('billing/form/update');
        }
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t(
            this.formType === 'ADD' ? 'billing.create.successMsg' : 'billing.update.successMsg',
          ),
          'success',
        );
        this.close();
        if (this.dispatchAfterString) {
          await this.$store.dispatch(this.dispatchAfterString, this.$route.params.uuid);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
